<template>
  <div>
    <div class="toefl-mian ">
      <div>
        <myimage :url='url'></myimage>
      </div>
      <div :class="screenWidth>=992?'toefl-service':'toefl-service-sm' ">
        <div class="backgroundfff-serve">
          <div class="service-con">
            <div class="servicetop">{{$t('toefl.考试形式')}}</div>
            <div class="servicebot"></div>
          </div>
          <div>
            <img src="../../assets/image/Toefl/test.png" alt="">
          </div>
        </div>
      </div>
      
      <div :class="screenWidth>=992?'toefl-service':'toefl-service-sm' " style="padding-top: 50PX ;">
        <div class="promain-service-bot">
          <div class="service-con">
            <div class="servicetop">{{$t('toefl.退考与转考')}}</div>
            <div class="servicebot"></div>
          </div>
          <div>
            <img src="../../assets/image/Toefl/out.png" alt="">
          </div>
        </div>
      </div>
      <div class="backgroundF7F">
        <div :class="screenWidth>=992?'toefl-service':'toefl-service-sm' ">
          <div class="backgroundF7F-serve">
            <!-- <div class="english-yellow">Suitable for people</div> -->
            <div class="service-con">
              <div class="servicetop">{{$t('toefl.成绩复议')}}</div>
              <div class="servicebot"></div>
            </div>
            <div>
              <img src="../../assets/image/Toefl/fy.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div :class="screenWidth>=992?'toefl-service':'toefl-service-sm' " style="padding-top: 50PX ;">
        <div class="promain-service-bot">
          <div class="service-con">
            <div class="servicetop">{{$t('toefl.考试流程')}}</div>
            <div class="servicebot"></div>
          </div>
          <div>
            <img src="../../assets/image/Toefl/lc.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myimage from '@/components/myimage'

export default {
  components: {
    myimage
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screenHeight: document.body.clientHeight,
      bannerList: [
        'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fe82575e1bc472edaeb120bb8976165ff7e8c71dd6cdd5-HVrxdx_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1654938807&t=c4ca465f95bca8716738d4c2c26a441e',
        'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fe82575e1bc472edaeb120bb8976165ff7e8c71dd6cdd5-HVrxdx_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1654938807&t=c4ca465f95bca8716738d4c2c26a441e',
        'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fe82575e1bc472edaeb120bb8976165ff7e8c71dd6cdd5-HVrxdx_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1654938807&t=c4ca465f95bca8716738d4c2c26a441e'
      ],
      url:require('@/assets/image/Toefl/bg.png')
    }
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth; //实时宽度
        window.screenHeight = document.documentElement.clientHeight; //实时高度
        console.log(this.screenWidth, this.screenHeight);
        that.screenWidth = window.screenWidth;
        that.screenHeight = window.screenHeight;
      })();
    };
  },
}
</script>

<style lang="scss" scoped>
.toefl-mian {
  width: 100vw;

}

.toefl-service-sm {
  // width: 90%;
  width: 1176PX;
  margin: 0 auto;
  padding-bottom: 50PX;
}

.toefl-service {
  // width: 75%;
  width: 1176PX;
  margin: 0 auto;
  padding-bottom: 50PX;
  display: flex;
  justify-content: center;
}

.toefl-service > div, .toefl-service-sm > div {
  position: relative;
  top: 0;
}

.backgroundfff-serve {
  height: 1580px;
  margin-top: 70PX;
}

.backgroundfff-serve img {
  width: 1176px;
  height: 1328px;
  margin-top: 100PX;
}

.backgroundAF6 {
  background: #FEFAF6;
  padding: 70px 0;
}

.backgroundAF6-serve {
  height: 260PX;
}

.backgroundAF6-serve img {
  width: 1176PX;
  height: 141PX;
  margin-top: 100PX;
}

.backgroundF7F {
  background: #f7f7f7;
  padding: 70px 0;
}

.backgroundF7F-serve {
  height: 616px;
}

.backgroundF7F-serve img {
  
  width: 1176px;
  height: 365px;
  margin-top: 100PX;
}

.english-yellow {
  color: #FE992B;
  font-size: 44px;
  opacity: 0.3;
}

.service-con {
  width: 100%;
//   position: absolute;
//   top: 15px;
}

.servicetop {

  font-size: 32px;
  color: #333;
  font-weight: bold;
  padding: 10PX 0;
}

.servicebot {
  width: 150px;
  height: 6PX;
  background: #FE992B;
  border-radius: 3px;
  margin: 0 auto;
}

.background-serve img {
  height: 500PX;
  width: 1176PX;
  margin-top: 100PX;
}

.promain-service-bot {
    width: 1170px;
  height: 1328px;
}
.promain-service-bot img{
    height:1078px;
  width: 1176px;
  margin-top: 100PX;
}
.all-service {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px;
}

.all-service img {
  width: 100%;
  height: 400px;
}

.probackgimage-service-two img {
  width: 1176PX;
  height: 102px;
}
</style>